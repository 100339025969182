import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import useGetEnums from "../../../../Utils/EnumsUtils";
import EnumsContext from "../../../../context/enums-context";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";

import classes from './NewUser.module.css';
import commonStyle from "../../CommonStyles.module.css";
import randomUserPicture from "../../../../images/Random_user_pic.png";

const NewUser = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();
    const [isEdit, setIsEdit]= useState(false);
    const [userDetails, setUserDetails] = useState({
        img: randomUserPicture,
        username: '',
        password: '',
        first_name: '',
        last_name: '',
        division: {
            division: null,
            employee_position: '',
        },
        email: '',
    });

    useGetEnums(["divisions"]);

    useEffect(() => {
        if (location.state !== null) {

            setIsEdit(true);

            const fetchData = async () => {

                const url = process.env.REACT_APP_BACKEND_URL + "auth/users/" + location.state.id + "/";
                const options = {
                    method: "GET",
                    headers: {
                        'Content-Type': 'application/json'
                    }
                };

                const response = await authenticatedRequest(url, options);
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                setUserDetails({
                    img: parsedResponse.logo === "" ? randomUserPicture : parsedResponse.logo,
                    username: parsedResponse.username,
                    password: '',
                    first_name: parsedResponse.first_name,
                    last_name: parsedResponse.last_name,
                    division: {
                        division: parsedResponse.division,
                        employee_position: parsedResponse.employee_position,
                    },
                    email: parsedResponse.email,
                    is_active: parsedResponse.is_active
                });
            }

            fetchData();
        }
    }, [location.state, authenticatedRequest]);

    console.log(userDetails);

    const ReturnButtonHandler = () => {
        navigate("/users");
    }

    const formik = useFormik({
        initialValues: userDetails,
        enableReinitialize: true,
        validate: values => {
            const errors = {};

            if (values.username === null || values.username === "") {
                errors.username = "Логін не повинен бути пустим"
            }

            if ((values.password === null || values.password === "") && location.state === null) {
                errors.password = "Пароль не повинен бути пустим"
            }

            if (values.division.division === null || values.division.division === "") {
                errors.division = "Виберіть підрозділ";
            }

            return errors
        },
        onSubmit: (values) => {

            values.division.division = Number(Object.keys(ctx.divisions).find(key => ctx.divisions[key] === values.division.division));
            console.log(values.division.division);
            console.log(values);

            if (location.state === null) {
                const addNewUserHandler = async event => {

                    const url = process.env.REACT_APP_BACKEND_URL + "auth/create_user/";
                    const options = {
                        method: "POST",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    };

                    const response = await authenticatedRequest(url, options);
                    //logging to remove later
                    console.log(response);

                    navigate("/users", { state: { values } });
                }

                addNewUserHandler();
            }
            else {

                const { password, division, ...rest } = values;

                values = {
                    division,
                    ...rest,
                };

                console.log(values);

                const editUserHandler = async event => {

                    const url = process.env.REACT_APP_BACKEND_URL + "auth/update_user/" + location.state.id + "/update/";
                    const options = {
                        method: "PUT",
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(values)
                    };

                    const response = await authenticatedRequest(url, options);
                    //logging to remove later
                    console.log(response);

                    const responseMessage = await response.json();

                    let toastMessage = { severity: "error", summary: isEdit ? "Помилка редагування" : "Помилка створення", 
                        detail: "Користувача не " + (isEdit ? "відредаговано. " : " створено. ") + " Код помилки: " + response.status + " Помилка: " + responseMessage.detail, life: 3000 };
    
                    if(isEdit && response.status === 200){
                        toastMessage = {severity: "success", summary: "Успіх редагування", detail: "Користувача успішно відредаговано", life: 3000}
                    }
                    else if(!isEdit && response.status === 201){
                        toastMessage = {severity: "success", summary: "Успіх створення", detail: "Користувача успішно створено", life: 3000}
                    }

                    navigate("/users", { state: { toast: toastMessage}});
                }

                editUserHandler();
            }
        }
    });

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нового користувача</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" severity="success" className={commonStyle.addButton} type="submit" />
                <Button label="Вийти" severity="secondary" type="button" className={commonStyle.closeButton} onClick={ReturnButtonHandler} />
            </div>
        );
    }

    return (<form onSubmit={formik.handleSubmit}>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate}></Toolbar>
        <div className="grid">
            <div className="col-3">
                <div className={classes.portraitdiv}>
                    <img className={classes.portrait} alt={formik.values.user} src={formik.values.img} style={{ borderRadius: "50%" }} />
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={formik.values.username}
                            onChange={(e) => { formik.setFieldValue('username', e.target.value) }}
                            className={formik.errors.username && formik.touched.username ? 'p-invalid' : ''}
                        />
                        <label>Логін</label>
                    </FloatLabel>
                    {formik.errors.username && formik.touched.username && (<small className={commonStyle.errorSmall}>{formik.errors.username}</small>)}
                </div>
                {location.state === null && <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText
                            value={formik.values.password}
                            onChange={(e) => { formik.setFieldValue('password', e.target.value) }}
                            className={formik.errors.password && formik.touched.password ? 'p-invalid' : ''}
                        />
                        <label>Пароль</label>
                    </FloatLabel>
                    {formik.errors.password && formik.touched.password && (<small className={commonStyle.errorSmall}>{formik.errors.password}</small>)}
                </div>}
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.division.employee_position} onChange={(e) => { formik.setFieldValue('division.employee_position', e.target.value) }} />
                        <label>Посада</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.last_name} onChange={(e) => { formik.setFieldValue('last_name', e.target.value) }} />
                        <label>Прізвище користувача</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.email} onChange={(e) => { formik.setFieldValue('email', e.target.value) }} />
                        <label>Email</label>
                    </FloatLabel>
                </div>
            </div>
            <div className="col-3">
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <InputText value={formik.values.first_name} onChange={(e) => { formik.setFieldValue('first_name', e.target.value) }} />
                        <label>Імя користувача</label>
                    </FloatLabel>
                </div>
                <div className={`${commonStyle.inputfields} flex-column p-inputgroup`}>
                    <FloatLabel>
                        <Dropdown
                            value={formik.values.division.division}
                            onChange={(e) => formik.setFieldValue('division.division', e.value)}
                            options={Object.values(ctx.divisions)}
                            showClear
                            className={formik.errors.division && formik.touched.division?.division ? 'p-invalid' : ''} />
                        <label>Підрозділ</label>
                    </FloatLabel>
                    {formik.errors.division && formik.touched.division?.division && (<small className={commonStyle.errorSmall}>{formik.errors.division}</small>)}
                </div>
            </div>
        </div>
    </form>);
}

export default NewUser;