import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Toolbar } from "primereact/toolbar";
import { Button } from "primereact/button";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import commonStyle from "../../CommonStyles.module.css";

const UserRoles = () => {

    const navigate = useNavigate();
    const toast = useRef(null);
    const authenticatedRequest = useAuthenticatedRequest();

    const [roles, setRoles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);

    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: null,
        sortOrder: null,
    });

    useEffect(() => {

        const fetchData = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "roles/get_list/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status !== 200){
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку", detail: "Помилка сервера " + response.status, life: 3000 });
                setTotalRecords(0);
                setRoles([]);
            }
            else{
                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);
    
                setTotalRecords(parsedResponse.count);
                setRoles(parsedResponse.results);
            }
            
            setLoading(false);
        }

        fetchData();

    }, [lazyState, authenticatedRequest]);

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const CreateNewRoleButtonHandler = () => {
        navigate('/roles/newuserrole');
    };

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати роль" className={commonStyle.editButton} severity="info" onClick={CreateNewRoleButtonHandler} />
        </div>)
    }

    const actionBodyTemplate = rowData => {
        return (<div className="flex flex-wrap gap-2">
            <Button
                icon={rowData.is_active ? "pi pi-times" : "pi pi-check"}
                className={`p-button-rounded ${rowData.is_active ? commonStyle.warningTag + " p-button-warning" : commonStyle.addButton + " p-button-success"}`}
                tooltip={rowData.is_active ? "Деактивувати" : "Активувати"}
                onClick={console.log(rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true }}
                disabled={!rowData.can_be_deleted}
                onClick={console.log(rowData)}
            />
            <Button
                icon="pi pi-pencil"
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                onClick={console.log(rowData)}
            />
        </div>
        );
    };

    return (<>
        <Toolbar className="mb-4" end={toolbarRightTemplate} />
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={roles} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]} tableStyle={{ minWidth: '60rem' }} >
                    <Column field="name" header="Назва ролі" style={{ width: '80%' }} sortable></Column>
                    <Column field="action" body={actionBodyTemplate} style={{ width: '20%' }} />
                </DataTable>
            </div>
        </div>
    </>);
}

export default UserRoles;