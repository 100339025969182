import { useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatCurrency } from "../../../../Utils/Utils";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { DataTable } from "primereact/datatable";
import { Toast } from 'primereact/toast';
import { Toolbar } from "primereact/toolbar";
import { Tooltip } from "primereact/tooltip";
import EnumsContext from "../../../../context/enums-context";
import useGetEnums from "../../../../Utils/EnumsUtils";

import commonStyle from "../../CommonStyles.module.css";

const ProductsReception = () => {

    const [loading, setLoading] = useState(true);
    const [totalRecords, setTotalRecords] = useState(0);
    const [acts, setActs] = useState(null);
    const [lazyState, setlazyState] = useState({
        first: 0,
        rows: 10,
        page: 1,
        sortField: "created_at",
        sortOrder: 1
    });
    const [reloadData, setReloadData] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();
    const toast = useRef(null);
    const ctx = useContext(EnumsContext);
    const authenticatedRequest = useAuthenticatedRequest();

    useGetEnums(["divisions", "partners", "pay_statuses"]);

    useEffect(() => {
        if(location.state !== null){
            toast.current.show(location.state.toast);
        }
        location.state = null;
    },[location.state, location]);

    useEffect(() => {
        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "receive_acts/get_list/?page=" + lazyState.page + "&page_size=" + lazyState.rows;
            url = lazyState.sortField == null ? url : url + "&ordering=" + (lazyState.sortOrder === 1 ? "-" + lazyState.sortField : lazyState.sortField);
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response !== null && response.status === 200) {

                const parsedResponse = await response.json();
                //logging to remove later
                console.log(parsedResponse);

                setTotalRecords(parsedResponse.count);
                setActs(parsedResponse.results);

            }
            else{
                setActs([]);
                toast.current.show({ severity: "error", summary: "Помилка завантаження списку актів", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            setLoading(false);
        }

        fetchData();

    }, [reloadData, lazyState, authenticatedRequest]);

    const FinalizeActHandler = (rowData) => {

        const finalizeAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/make_finalized/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 200){ 
                toast.current.show({ severity: "error", summary: "Помилка фіналізації", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: 'success', summary: 'Фіналізація', detail: 'Акт фіналізовано', life: 3000 });
            }

            setReloadData(!reloadData);
        }

        finalizeAct();
    }

    const DeleteButtonHandler = rowData => {
        const deleteAct = async () => {

            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/delete/" + rowData.id + "/";
            const options = {
                method: "DELETE",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            //logging to remove later
            console.log(response);

            if (response.status !== 204){ 
                toast.current.show({ severity: "error", summary: "Помилка видалення", detail: "Помилка сервера " + response.status, life: 3000 });
            }
            else
            {
                toast.current.show({ severity: "success", summary: 'Видалення', detail: 'Акт успішно видалено', life: 3000 });
            }
            setReloadData(!reloadData);
        }

        deleteAct();
    }

    const onPage = (event) => {
        event.page = event.page +1;
        setlazyState(event);
    };

    const onSort = (event) => {
        event.page = lazyState.page;
        setlazyState(event);
    }

    const CreateNewSaleButtonHandler = () => {
        navigate("/productsreception/newreception");
    }

    const confirmDelete = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть видалення акту',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {DeleteButtonHandler(rowData)}
        });
    }

    const confirmFinalize = (event, rowData) => {
        confirmPopup({
            group: 'headless',
            target: event.currentTarget,
            message: 'Підтвердіть фіналізацію акту. Ви не будете мати можливості редагувати акт',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: "Так",
            rejectLabel: "Hi",
            defaultFocus: 'accept',
            accept: () => {FinalizeActHandler(rowData)}
        });
    }

    const EditProductHandler = rowData => {
        const route = '/productsreception/editreception/' + rowData.id;
        navigate(route, { state: { id: rowData.id } });
    }

    /*const printPriceTag = rowData => {

        const getActDetails = async() => {
            const url = process.env.REACT_APP_BACKEND_URL + "receive_acts/get_details/" + rowData.id + "/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);

            if (response.status === 200) {

                const parsedResponse = await response.json();

                const productsToPrint = [];

                if (parsedResponse.identified_products !== undefined && parsedResponse.identified_products.length > 0) {
                    parsedResponse.identified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.id;
                        newProduct.accounting_type = "INDIVIDUAL";
                        productsToPrint.push(newProduct);
                    });
                }

                if (parsedResponse.unidentified_products !== undefined && parsedResponse.unidentified_products.length > 0) {
                    parsedResponse.unidentified_products.forEach(product => {
                        const newProduct = {};
                        newProduct.remaining_id = product.product_id;
                        newProduct.accounting_type = "PIECE";
                        productsToPrint.push(newProduct);
                    });
                }

                return productsToPrint;
            }
            else{
                toast.current.show({ severity: "error", summary: "Помилка завантаження деталей акту", detail: "Помилка сервера " + response.status, life: 3000 });
                return null;
            }
        }

        const printPriceTags = async() => {

            const productsToPrintPrice = await getActDetails();
            console.log(productsToPrintPrice);

            const url = process.env.REACT_APP_BACKEND_URL + "remainings/get_pdf_with_qr_code/";
            const options = {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(productsToPrintPrice)
            };

            const response = await authenticatedRequest(url, options);
            if(response.status === 200){
                console.log(response);
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
        
                // Set a filename
                link.download = `price_${rowData.id}.pdf`; // Change based on your needs
                document.body.appendChild(link);
                link.click();
        
                // Clean up
                link.remove();
                window.URL.revokeObjectURL(url);
            }
            else{
                toast.current.show({ severity: 'error', summary: 'Помилка завантаження', detail: 'Помилка завантадення цінника', life: 3000 });
            }
        }

        printPriceTags();
    } */

    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт прийому товару" severity="success" className={commonStyle.addButton} onClick={CreateNewSaleButtonHandler} />
        </div>)
    }

    const cagentTemplate = (rowData) => {
        return ctx.partners[rowData.cagent];
    }

    const divisionTemplate = (rowData) => {
        return ctx.divisions[rowData.division];
    }

    const payStatusesTemplate = (rowData) => {
        return ctx.pay_statuses[rowData.pay_status];
    }

    const StatusCircle = ({ rowData }) => {
        return (
            <div>
                {rowData.status === "PENDING" ? (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.pending}`}
                        data-pr-tooltip="Драфт"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                ) : (
                    <div
                        className={`${commonStyle.statusCircle} ${commonStyle.active}`}
                        data-pr-tooltip="Фіналізований"
                        data-pr-position="top"
                        id={`statusCircle-${rowData.id}`}
                    ></div>
                )}
                <Tooltip target={`#statusCircle-${rowData.id}`} />
            </div>
        );
    };

    const statusBodyTemplate = (rowData) => <StatusCircle rowData={rowData} />;

    const formatDate = (value) => {
        return new Date(value).toLocaleDateString('ua-UA', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    };

    const dateBodyTemplate = rawElement => {
        return formatDate(rawElement.created_at);
    };

    const actionBodyTemplate = (rowData) => {
        return (<>
        <div className="flex flex-wrap gap-2">
            <Button
                icon="pi pi-lock"
                className={`p-button-rounded p-button-success ${rowData.status !== "FINALIZED" ? commonStyle.addButton : commonStyle.closeButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Фіналізувати" : "Закритий"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={rowData.status === "FINALIZED"}
                onClick={(event) => confirmFinalize(event, rowData)}
            />
            <Button
                icon="pi pi-trash"
                className={`p-button-rounded p-button-danger ${rowData.can_be_deleted ? commonStyle.deleteButton : commonStyle.closeButton}`}
                tooltip={rowData.can_be_deleted ? "Видалити" : "Неможливо видалити"}
                tooltipOptions={{ showOnDisabled: true, position: "top" }}
                disabled={!rowData.can_be_deleted}
                onClick={(event) => confirmDelete(event, rowData)}
            />
            <Button
                icon={rowData.status !== "FINALIZED" ? "pi pi-pencil" : "pi pi-eye"}
                className={`p-button-rounded p-button-info ${commonStyle.editButton}`}
                tooltip={rowData.status !== "FINALIZED" ? "Редагувати" : "Деталі акту"}
                tooltipOptions={{ position: "top" }}
                onClick={() => EditProductHandler(rowData)}
            />
        </div>
        </>);
    };

    return (<>
        <Toast ref={toast} />
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-12 justify-content-center'>
                <DataTable value={acts} stripedRows lazy rows={lazyState.rows} paginator onPage={onPage} onSort={onSort} sortField={lazyState.sortField} sortOrder={lazyState.sortOrder}
                first={lazyState.first} loading={loading} totalRecords={totalRecords} rowsPerPageOptions={[10, 20, 50]}
                 emptyMessage="Актів не знайдено" tableStyle={{ minWidth: '60rem' }} >
                    <Column field="act_number" header="Номер акту" sortable/>
                    <Column field="act_date" dataType="date" header="Дата акту" sortable/>
                    <Column field="cagent" header="Контрагент" body={cagentTemplate}/>
                    <Column field="division" header="Підрозділ" body={divisionTemplate}/>
                    <Column field="amountUAH" header="Сума акту, грн" body={rowData => formatCurrency(rowData.amountUAH, "UAH")}/>
                    <Column field="pay_status" header="Статус оплати" body={payStatusesTemplate}/>
                    <Column field="pay_due_date" header="Дата оплати"/>
                    <Column field="status" header="Статус" style={{ width: '4%' }} body={statusBodyTemplate} sortable/>
                    <Column field="created_by" header="Менеджер" sortable/>
                    <Column field="created_at" header="Дата" body={dateBodyTemplate} sortable/>
                    <Column field="action" body={actionBodyTemplate} />
                </DataTable>
            </div>
            <ConfirmPopup group="headless" />
        </div>
    </>);
}

export default ProductsReception;