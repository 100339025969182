import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { FloatLabel } from "primereact/floatlabel";
import { InputText } from "primereact/inputtext";
import { Toolbar } from "primereact/toolbar";
import { RadioButton } from "primereact/radiobutton";

import commonStyle from "../../CommonStyles.module.css";

const NewUserRole = () => {

    const navigate = useNavigate();
    const authenticatedRequest = useAuthenticatedRequest();

    const [permissions, setPermissions] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            let url = process.env.REACT_APP_BACKEND_URL + "enums/permissions/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            //logging to remove later
            console.log(parsedResponse);
            parsedResponse.forEach(permission => {
                permission.allowed = false;
            });

            setPermissions(parsedResponse);
        }

        fetchData();

    }, [authenticatedRequest]);


    const formik = useFormik({
        initialValues: {
            name: "",
            permissions: permissions
        },
        enableReinitialize: true,
        validate: values => {
            const errors = [];
            if(values.name === null || values.name === ""){
                errors.name = "Введіть назву ролі";
            }
            return errors;
        },
        onSubmit: values => {
            console.log(values);
        }
    })

    const ReturnButtonHandler = () => {
        navigate("/roles");
    }

    const mainToolbarLeftTemplate = () => {
        return (<h3>Створення нової ролі користувача</h3>);
    }

    const mainToolbarRightTemplate = () => {
        return (
            <div className="flex flex-wrap gap-2">
                <Button label="Зберегти" className={commonStyle.addButton} severity="success" type="submit"/>
                <Button label="Вийти" className={commonStyle.deleteButton} severity="secondary" type="button" onClick={ReturnButtonHandler} />
            </div>
        );
    }

    const groupByApp = (permissions) => {
        return permissions.reduce((acc, permission) => {
            const { app } = permission;
            if (!acc[app]) {
                acc[app] = [];
            }
            acc[app].push(permission);
            return acc;
        }, {});
    };

    const PermissionsList = ({ permissions }) => {
        // Group permissions by category
        const groupedPermissions = groupByApp(permissions);
    
        return (
            <div>
                {Object.keys(groupedPermissions).map(app => (
                    <div key={app} className="grid">
                        <h3 className="col-12">{app}</h3>
                        {groupedPermissions[app].map(permission => (
                            <div className="col-4 flex align-items-center" key={permission.id}>
                                <RadioButton checked={permission.allowed} />
                                <label className="ml-2">{permission.name}</label>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    return (<form>
        <Toolbar className="mb-4" start={mainToolbarLeftTemplate} end={mainToolbarRightTemplate} />
        <div className="grid nested-grid">
            <div className={`${commonStyle.leftMenu} col-3`}>
                <div className={commonStyle.formInput}>
                    <div className="p-inputgroup flex-column">
                        <FloatLabel>
                            <InputText
                                name="name"
                                value={formik.values.name}
                                onChange={(e) => { formik.setFieldValue('name', e.target.value) }}
                                className={formik.errors.name && formik.touched.name ? 'p-invalid' : ''}
                            />
                            <label>Назва ролі</label>
                        </FloatLabel>
                        {formik.errors.name && formik.touched.name && (<small className={commonStyle.errorSmall}>{formik.errors.name}</small>)}
                    </div>
                </div>
            </div>
            <div className="col-9">
                <PermissionsList permissions={formik.values.permissions} />
            </div>
        </div>
    </form>);
}

export default NewUserRole;