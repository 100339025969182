import { PanelMenu } from 'primereact/panelmenu';
import { useNavigate } from 'react-router-dom';

import './NavigationBar.module.css';
import React, { useState } from 'react';

const NavigationBar = params => {

    const [expandedKeys, setExpandedKeys] = useState({});
    
    const navigate = useNavigate();

    const closeSideBar = () =>{
        params.switchPage();
    }

    const items = [
        {
            key: "1",
            label: 'Дашборд',
            icon: 'pi pi-fw pi-chart-line',
            items: [
                {
                    label: 'Віджети',
                    command: (event) => {
                        navigate('/widgets');
                        closeSideBar();
                    }
                },
                /*{
                   // label: 'Динаміка продажів',
                },
                {
                    label: 'Порівняння проданих товарів',
                },
                {
                    label: 'Фінансовий результат',
                }*/
            ]
        },
        {
            key: "2",
            label: 'Товари та послуги',
            icon: 'pi pi-shopping-cart',
            items: [
                {
                    label: 'Групи товарів',
                    command: () => {
                        navigate('/productgroups');
                        closeSideBar();
                    }
                },
                {
                    label: 'Перелік товарів',
                    command: () => {
                        navigate('/products');
                        closeSideBar();
                    }
                },
                {
                    label: 'Групи послуг',
                    command: () => {
                        navigate('/servicegroups');
                        closeSideBar();
                    }
                },
                {
                    label: 'Перелік послуг',
                    command: () => {
                        navigate('/services');
                        closeSideBar();
                    }
                }
            ]
        },
        {
            key: "3",
            label: "Облік",
            icon: 'pi pi-fw pi-calendar',
            items: [
                {
                    label: 'Прийом товарів',
                    command: () => {
                        navigate('/productsreception');
                        closeSideBar();
                    }
                },
                /*{
                    label: 'Переміщення товарів',
                    command: () => {
                        navigate('/productsmove');
                    } 
                },*/
                {
                    label: 'Залишки',
                    command: () => {
                        navigate('/leftovers');
                        closeSideBar();
                    }
                },
                /*{
                    label: 'Списання',
                     command: () => {
                        navigate('/writeoff');
                    }
                },*/
                /*{
                    label: 'Повернення',
                     command: () => {
                        navigate('/productsreturn');
                    }
                },
                {
                    label: 'Зовнішні товари',
                }*/
            ]
        },
        {
            key: "4",
            label: "Продажі",
            icon: 'pi pi-fw pi-money-bill',
            items: [
                {
                    label: 'Продажі та послуги',
                    command: () => {
                        navigate('/sales');
                        closeSideBar();
                    }
                },
                /*{
                    label: 'Повернення клієнтів',
                     command: () => {
                        navigate('/productsreturn');
                    }
                }*/
            ]
        },
        {
            key: "5",
            label: "Контрагенти ",
            icon: 'pi pi-fw pi-comment',
            items: [
                {
                    label: 'Постачальники',
                    command: () => {
                        navigate('/partners');
                        closeSideBar();
                    }
                },
                {
                    label: 'Клієнти',
                    command: () => {
                        navigate('/clients');
                        closeSideBar();
                    }
                }
            ]
        },
        {
            key: "6",
            label: "Бухгалтерія",
            icon: 'pi pi-fw pi-paperclip',
            items: [
                {
                    label: 'Платежі',
                    command: () => {
                        navigate("/payments");
                        closeSideBar();
                    }
                },
                {
                    label: 'Транзакції',
                    command: () => {
                        navigate("/transactions");
                        closeSideBar();
                    }
                },
 
            ]
        },
        {
            key: "7",
            label: "Налаштування",
            icon: 'pi pi-fw pi-cog',
            items: [
                {
                    label: 'Налаштування організації',
                    command: () => {
                        navigate('/organization');
                        closeSideBar();
                    }
                },
                {
                    label: 'Користувачі',
                    command: () => {
                        navigate('/users');
                        closeSideBar();
                    }
                },
                {
                    label: 'Ролі користувача',
                    command: () => {
                        navigate('/roles');
                        closeSideBar();
                    }
                },
                {
                    label: 'Ієрархія підрозділів',
                    command: () => {
                        navigate('/divisions');
                        closeSideBar();
                    }
                },
                {
                    label: 'Валюта',
                    command: () => {
                        navigate('/currencies');
                        closeSideBar();
                    }
                },
                /*{
                    label: 'Маркетплейс',
                }*/
            ]
        }
    ];
  
    return <div className="card flex justify-content-left">
        <PanelMenu
            model={items}
            expandedKeys={expandedKeys} 
            onExpandedKeysChange={setExpandedKeys}
            pt={{
                root: { className: 'w-full' },
                headerAction: ({ context }) => ({ className: context.active ? 'bg-primary-200' : undefined }),
                action: ({ context }) => ({ className: context.active ? 'bg-primary-100' : undefined })
            }}
        />
    </div>
}

export default NavigationBar;