import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Toolbar } from "primereact/toolbar";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";

const ProductsReturn = () => {

    const returnedProducts = [{
        id: 112,
        date: "2015-09-13",
        unit: "Основний склад",
        counterparty: "The Case",
        status: "Закритий",
        sum: 49500.00,
        currency: "UAH",
        sumPaid: 49500.00,
        paidCurrency: "UAH",
        user: "Марян Коць"
    },
    {
        id: 113,
        date: "2019-02-09",
        unit: "Основний склад",
        counterparty: "The Case",
        status: "Закритий",
        sum: 840.00,
        currency: "USD",
        sumPaid: 31920.00,
        paidCurrency: "UAH",
        user: "Марян Коць"
    },
    {
        id: 114,
        date: "2015-09-13",
        unit: "Основний склад",
        counterparty: "Apple Market Lviv",
        status: "Закритий",
        sum: 800.00,
        currency: "USD",
        sumPaid: 800.00,
        paidCurrency: "USD",
        user: "Марян Коць"
    },
    {
        id: 115,
        date: "2017-05-13",
        unit: "Основний склад",
        counterparty: "ФОП Миць",
        status: "Драфт",
        sum: 39500.00,
        currency: "UAH",
        sumPaid: 0.00,
        paidCurrency: "UAH",
        user: "Марян Коць"
    },
    {
        id: 116,
        date: "2017-05-13",
        unit: "Основний склад",
        counterparty: "ФОП Миць",
        status: "На узгодженні",
        sum: 500.00,
        currency: "USD",
        sumPaid: 0.00,
        paidCurrency: "UAH",
        user: "Марян Коць"
    },
    {
        id: 117,
        date: "2017-05-13",
        unit: "Основний склад",
        counterparty: "Apple Market Lviv",
        status: "Очікує оплату",
        sum: 500.00,
        currency: "USD",
        sumPaid: 0.00,
        paidCurrency: "UAH",
        user: "Марян Коць"
    },
    ];

    const navigate = useNavigate();

    const [selectedProduct, setSelectedProduct] = useState(null);

    const CreateNewProductsReturnButtonHandler = () => {
        navigate("/productsreturn/newproductsreturn");
    }

    const modifiedReturn = () => {
        returnedProducts.forEach(element => {
            if (element.currency !== "UAH") {
                element.sumUAH = element.sum * 38
            }
            else {
                element.sumUAH = element.sum
            }
        });
        return returnedProducts
    };

    const getStatusSeverity = type => {
        switch (type) {
            case "Закритий":
                return "success";
            case "Очікує оплату":
                return "danger";
            case "Драфт":
                return "info";
            case "На узгодженні":
                return "warning";
            default:
                return "info";
        }
    }

    const statusBodyTemplate = rawElement => {
        const severity = getStatusSeverity(rawElement.status);
        return (<Tag value={rawElement.status} severity={severity} />)
    }

    const priceBodyTemplate = (value, currency) => {
        let locale = "ua-UA";

        if (currency === "USD") {
            locale = "en-EN";
        }
        return value.toLocaleString(locale, { style: 'currency', currency: currency });
    };

    const sumBodyTemplate = rawElement => {
        return priceBodyTemplate(rawElement.sum, rawElement.currency);
    }

    const payedBodyTemplate = rawElement => {
        return priceBodyTemplate(rawElement.sumPaid, rawElement.paidCurrency);
    }

    const UahBodyTemplate = rawElement => {
        return priceBodyTemplate(rawElement.sumUAH, "UAH");
    }


    const toolbarRightTemplate = () => {
        return (<div className="flex flex-wrap gap-2">
            <Button label="Додати акт повернення товару" severity="info" onClick={CreateNewProductsReturnButtonHandler} />
        </div>)
    }

    return (<>
        <Toolbar className="mb-4" end={toolbarRightTemplate}></Toolbar>
        <div className='grid'>
            <div className='col-11 justify-content-center'>
                <DataTable value={modifiedReturn()} selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)} rows={10} tableStyle={{ minWidth: '60rem' }} >
                    <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                    <Column field="id" header="№" sortable></Column>
                    <Column field="date" dataType="date" header="Дата акту" ></Column>
                    <Column field="unit" header="Підрозділ" sortable></Column>
                    <Column field="counterparty" header="Контрагент" sortable></Column>
                    <Column field="sum" header="Сума" body={sumBodyTemplate}></Column>
                    <Column field="sumUAH" header="Сума, грн" sortable body={UahBodyTemplate}></Column>
                    <Column field="sumPaid" header="Оплачено" body={payedBodyTemplate} ></Column>
                    <Column field="status" header="Статус" sortable body={statusBodyTemplate}></Column>
                    <Column field="user" header="Менеджер" sortable></Column>
                </DataTable>
            </div>
        </div>
    </>);
}

export default ProductsReturn;