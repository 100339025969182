import { Card } from "primereact/card";
import { useEffect, useState } from "react";
import useAuthenticatedRequest from "../../../../Utils/TokenrRefreshUtils";
import { ProgressSpinner } from "primereact/progressspinner";

const DashBoards = () => {

    const authenticatedRequest = useAuthenticatedRequest();

    const [expenses, setExpenses] = useState();
    const [expensesResult, setExpensesResult] = useState();
    const [expensesLoaded, setExpensesLoaded] = useState(false);
    const [productsSold, setProductsSold] = useState();
    const [productsResult, setProductsResult] = useState();
    const [productsSoldLoaded, setProductsSoldLoaded] = useState(false);
    const [servicesSold, setServicesSold] = useState();
    const [servicesResult, setServicesResult] = useState();
    const [servicesSoldLoaded, setServicesSoldLoaded] = useState(false);
    const [revenue, setRevenue] = useState();
    const [revenueResult, setRevenueResult] = useState();
    const [revenueLoaded, setRevenueLoaded] = useState(false);

    const calculateResult = (widgetData) => {

        let result = 0;

        if (widgetData.previous_value !== 0) {
            result = Math.round((widgetData.current_value / widgetData.previous_value) * 100 - 100);
        }
        else {
            if (widgetData.current_value !== 0) {
                result = 100;
            }
        }

        const style = result === 0 ? "" : result > 0 ? "text-green-500" : "text-red-500";

        return {
            result: result,
            style: style
        }
    }

    useEffect(() => {

        const fetchData = async () => {
            const url = process.env.REACT_APP_BACKEND_URL + "dashboards/costs_widget/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            setExpenses(parsedResponse);
            setExpensesResult(calculateResult(parsedResponse));
            setExpensesLoaded(true);
        };

        fetchData();
    }, [authenticatedRequest]);

    useEffect(() => {

        const fetchData = async () => {
            const url = process.env.REACT_APP_BACKEND_URL + "dashboards/count_products_widget/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            setProductsSold(parsedResponse);
            setProductsResult(calculateResult(parsedResponse))
            setProductsSoldLoaded(true);
        };

        fetchData();
    }, [authenticatedRequest]);

    useEffect(() => {

        const fetchData = async () => {
            const url = process.env.REACT_APP_BACKEND_URL + "dashboards/count_services_widget";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            setServicesSold(parsedResponse);
            setServicesResult(calculateResult(parsedResponse));
            setServicesSoldLoaded(true);
        };

        fetchData();
    }, [authenticatedRequest]);

    useEffect(() => {

        const fetchData = async () => {
            const url = process.env.REACT_APP_BACKEND_URL + "dashboards/revenue_widget/";
            const options = {
                method: "GET",
                headers: {
                    'Content-Type': 'application/json'
                }
            };
            const response = await authenticatedRequest(url, options);
            const parsedResponse = await response.json();

            setRevenue(parsedResponse);
            setRevenueResult(calculateResult(parsedResponse));
            setRevenueLoaded(true);
        };

        fetchData();
    }, [authenticatedRequest]);

    return (<><div className="grid">
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!expensesLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {expensesLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{expenses?.name}</span>
                        <div className="text-900 font-medium text-xl">{expenses?.current_value.toLocaleString("ua-UA", { style: 'currency', currency: "UAH" })}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-blue-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-shopping-cart text-blue-500 text-xl" />
                    </div>
                </div>
                    <span className={`${expensesResult?.style} font-medium`}>{expensesResult?.result}% </span>
                    <span className="text-500">в період з {expenses?.start_date} до {expenses?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!productsSoldLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {productsSoldLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{productsSold?.name}</span>
                        <div className="text-900 font-medium text-xl">{productsSold?.current_value}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-orange-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-phone text-orange-500 text-xl" />
                    </div>
                </div>
                    <span className={`${productsResult?.style} font-medium`}>{productsResult?.result}% </span>
                    <span className="text-500">в період з {productsSold?.start_date} до {productsSold?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!servicesSoldLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {servicesSoldLoaded && (<><div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{servicesSold?.name}</span>
                        <div className="text-900 font-medium text-xl">{servicesSold?.current_value}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-cyan-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-wrench text-cyan-500 text-xl" />
                    </div>
                </div>
                    <span className={`${servicesResult?.style} font-medium`}>{servicesResult?.result}% </span>
                    <span className="text-500">в період з {servicesSold?.start_date} до {servicesSold?.end_date}</span>
                </>)}
            </Card>
        </div>
        <div className="col-12 lg:col-6 xl:col-3">
            <Card className="mb-0">
                {!revenueLoaded && <div className="card flex justify-content-center">
                    <ProgressSpinner />
                </div>}
                {revenueLoaded && (<>
                <div className="flex justify-content-between mb-3">
                    <div>
                        <span className="block text-500 font-medium mb-3">{revenue?.name}</span>
                        <div className="text-900 font-medium text-xl">{revenue?.current_value.toLocaleString("ua-UA", { style: 'currency', currency: "UAH" })}</div>
                    </div>
                    <div className="flex align-items-center justify-content-center bg-green-100 border-round" style={{ width: "3rem", height: "3rem" }}>
                        <i className="pi pi-money-bill text-green-500 text-xl" />
                    </div>
                </div>
                    <span className={`${revenueResult?.style} font-medium`}>{revenueResult?.result}% </span>
                    <span className="text-500">в період з {revenue?.start_date} до {revenue?.end_date}</span>
                </>)}
            </Card>
        </div>
    </div></>)
}

export default DashBoards;