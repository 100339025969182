import { createContext, useState } from "react";
import useAuthenticatedRequest from "../Utils/TokenrRefreshUtils";

const UserPermissionContext = createContext();

const UserPermissionProvider = ({ children }) => {

    const [permissions, setPermissions] = useState(null);

    const authenticatedRequest = useAuthenticatedRequest();

    const loadPermissions = async (id) => {
        try {

            const url = `${process.env.REACT_APP_BACKEND_URL}auth/get_user_roles/${id}/`;
            const options = {
                method: "GET",
                headers: { 'Content-Type': 'application/json' }
            };
            const response = await authenticatedRequest(url, options);

            if(response.status === 200){
                const parsedResponse = await response.json();
                console.log("permission called:", parsedResponse);

                setPermissions(parsedResponse.roles);
            }

        } catch (parsedResponse) {
            return parsedResponse.response.status;
        }
    };

    const value = {
        permissions,
        loadPermissions
    };

    return (
        <UserPermissionContext.Provider value={value}>
            {children}
        </UserPermissionContext.Provider>
    );
}

export { UserPermissionProvider, UserPermissionContext };