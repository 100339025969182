// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NewUser_portraitdiv__g-cbY {
    display: grid;
    justify-content: center;
}
.NewUser_portrait__ywe6O{
    width: 20rem;
}
.NewUser_inputfields__Z\\+wdb {
    margin-top: 25px;
}`, "",{"version":3,"sources":["webpack://./src/components/content/PageContent/Settings/NewUser.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,YAAY;AAChB;AACA;IACI,gBAAgB;AACpB","sourcesContent":[".portraitdiv {\n    display: grid;\n    justify-content: center;\n}\n.portrait{\n    width: 20rem;\n}\n.inputfields {\n    margin-top: 25px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"portraitdiv": `NewUser_portraitdiv__g-cbY`,
	"portrait": `NewUser_portrait__ywe6O`,
	"inputfields": `NewUser_inputfields__Z+wdb`
};
export default ___CSS_LOADER_EXPORT___;
